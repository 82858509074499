<template>
  <IntegrationSettingsMenu
    name="Zendesk"
    :features="features"
    :preferences="preferences"
    :integration="integration.value"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      name="1"
      :title="$t('settings.integration.zendesk.step-one.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-text">
          {{ $t('settings.integration.zendesk.step-one.one') }}
        </div>
      </div>
      <div class="step-item">
        <div v-if="state.token" class="step-action">
          <base-input
            disabled
            :model-value="companyToken"
            @update:model-value="companyToken = $event.detail[0]"
          ></base-input>
          <base-button
            :text="$t('settings.integration.zendesk.step-one.three-copy')"
            class="ml-2"
            variant="tertiary"
            size="sm"
            @click="copyToken"
          ></base-button>
        </div>
        <base-button
          v-else
          :text="$t('settings.integration.zendesk.step-one.two-generate')"
          variant="primary"
          size="sm"
          @click="state.token = true"
        ></base-button>
      </div>
    </div>
    <IntegrationSettingsStep
      name="2"
      :title="$t('settings.integration.zendesk.step-two.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-text">
          {{ $t('settings.integration.zendesk.step-two.one') }}
        </div>
        <div class="step-text">
          {{ $t('settings.integration.zendesk.step-two.two') }}
        </div>
      </div>
      <div class="step-item">
        <base-button
          :text="$t('settings.integration.zendesk.step-two.install')"
          variant="primary"
          size="sm"
          @click="
            openLink('https://www.zendesk.com/apps/support/mayday/?q=mkp_mayda')
          "
        ></base-button>
      </div>
    </div>
    <IntegrationSettingsStep
      name="3"
      :title="$t('settings.integration.zendesk.step-three.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <ol>
          <li>
            {{ $t('settings.integration.zendesk.step-three.one') }}
          </li>
          <li>
            {{ $t('settings.integration.zendesk.step-three.two.title') }}
          </li>
          <div class="step-info">
            <p>
              {{
                $t('settings.integration.zendesk.step-three.two.client-name')
              }}
            </p>
            <p>
              {{ $t('settings.integration.zendesk.step-three.two.company') }}
            </p>
            <p>
              {{
                $t(
                  'settings.integration.zendesk.step-three.two.unique-identifier',
                )
              }}
            </p>
            <p>
              {{
                $t(
                  'settings.integration.zendesk.step-three.two.redirection-url',
                )
              }}
              https://api.mayday.fr/auth/zendesk
            </p>
          </div>
          <li>
            {{ $t('settings.integration.zendesk.step-three.four.title') }}
          </li>
          <div class="step-input">
            <span class="label">
              {{
                $t(
                  'settings.integration.zendesk.step-three.four.subdomain-label',
                )
              }}</span
            >
            <base-input
              :placeholder="
                state.success
                  ? '· · · · · · · ·'
                  : $t(
                      'settings.integration.zendesk.step-three.four.subdomain-placeholder',
                    )
              "
              :model-value="model.subdomain"
              @update:model-value="model.subdomain = $event.detail[0]"
              :disabled="state.success"
            ></base-input>

            <span class="description">
              {{
                $t(
                  'settings.integration.zendesk.step-three.four.subdomain-todo',
                )
              }}</span
            >
          </div>
          <div class="step-input">
            <span class="label">{{
              $t(
                'settings.integration.zendesk.step-three.four.unique-identifier-label',
              )
            }}</span>
            <base-input
              :placeholder="
                $t(
                  'settings.integration.zendesk.step-three.four.unique-identifier-placeholder',
                )
              "
              :model-value="model.clientId"
              @update:model-value="model.clientId = $event.detail[0]"
              :disabled="state.success"
            ></base-input>
          </div>
          <div class="step-input">
            <span class="label">{{
              $t('settings.integration.zendesk.step-three.four.secret-label')
            }}</span>
            <base-input
              :placeholder="
                state.success
                  ? '· · · · · · · ·'
                  : $t(
                      'settings.integration.zendesk.step-three.four.secret-placeholder',
                    )
              "
              :model-value="model.clientSecret"
              @update:model-value="model.clientSecret = $event.detail[0]"
              :disabled="state.success"
            ></base-input>
          </div>
          <div class="step-item">
            <base-button
              v-if="!state.success"
              :text="$t('settings.integration.zendesk.step-three.four.connect')"
              variant="primary"
              size="sm"
              :disabled="$v.model.$invalid"
              @click="initializeZendeskAuth()"
            ></base-button>
            <base-button
              v-else
              :text="$t('settings.integration.zendesk.step-three.four.modify')"
              variant="primary"
              size="sm"
              @click="state.success = false"
            ></base-button>
          </div>
        </ol>
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'integration-settings-zendesk',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: {
        token: true,
        creds: false,
        success: false,
      },
      pluginIntegrationName: 'Zendesk App',
      companyToken:
        '· · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·',
      model: {
        subdomain: '',
        clientId: 'mayday',
        clientSecret: '',
      },
      preferences: {},
    };
  },
  async mounted() {
    const { zendesk_success } = this.$route.query;
    if (zendesk_success !== undefined) {
      this.respIntegrationMessage(zendesk_success);
    }
    this.state.success = await this.checkAuthExists('zendesk');
    this.preferences = {
      ZENDESK_SEARCH_NO_STEPS: {
        title: this.$t(
          'settings.card-settings-options.integration-search.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-search.description',
        ),
        iconName: ['fas', 'search'],
        notification: this.$t(
          'settings.card-settings-options.integration-search.notification',
        ),
        hasRule: false,
      },
      ZENDESK_NO_TAGS_UPDATE: {
        title: this.$t('settings.card-settings-options.integration-tags.title'),
        description: this.$t(
          'settings.card-settings-options.integration-tags.description',
        ),
        iconName: ['fas', 'tags'],
        notification: this.$t(
          'settings.card-settings-options.integration-tags.notification',
        ),
        hasRule: false,
      },
      ZENDESK_MAYDAY_TAG_TRACKER: {
        title: this.$t(
          'settings.card-settings-options.integration-mayday-tag.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-mayday-tag.description',
        ),
        iconName: ['fas', 'tags'],
        notification: this.$t(
          'settings.card-settings-options.integration-mayday-tag.notification',
        ),
        hasRule: false,
      },
      ZENDESK_AUTOMATION_DISPLAY_DELAY: {
        title: this.$t(
          'settings.card-settings-options.integration-automation.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-automation.description',
        ),
        iconName: ['fas', 'stopwatch-20'],
        timeRange: this.$t(
          'settings.card-settings-options.integration-automation.time-range',
        ),
        defaultValue: 15,
        rule: this.$t(
          'settings.card-settings-options.integration-automation.rule',
        ),
        notification: this.$t(
          'settings.card-settings-options.integration-automation.notification',
        ),
        hasRule: true,
      },
      ZENDESK_PRIMARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-primary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-primary-color.description',
        )} Zendesk`,
        iconName: ['fas', 'palette'],
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyZendeskPrimaryColor,
        notification: this.$t(
          'settings.card-settings-options.integration-color.notification',
        ),
        hasRule: false,
      },
      ZENDESK_SECONDARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-secondary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-secondary-color.description',
        )} Zendesk`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyZendeskSecondaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-secondary-color.notification',
        ),
        hasRule: false,
      },
      ALLOW_EMBEDDED_IFRAME: {
        title: this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.title',
        ),
        description: `${this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.short-description',
        )}`,
        iconName: ['fal', 'code'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_EMBEDDED_IFRAME'),
        pluginType: 'EmbeddedIframeEntity',
      },
      ALLOW_TRENDING_CONTENT: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.description',
        )}`,
        iconName: ['fal', 'arrow-trend-up'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_TRENDING_CONTENT'),
        pluginType: 'TrendingContentEntity',
      },
      DESK_NEW_CONTENTS_PLUGIN: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.new-contents.description',
        )}`,
        iconName: ['fas', 'align-left'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('DESK_NEW_CONTENTS_PLUGIN'),
        pluginType: 'NewContentEntity',
      },
      ALLOW_SMART_ACTIONS: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.description',
        )}`,
        iconName: ['fas', 'arrow-up-right-from-square'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_SMART_ACTIONS'),
        pluginType: 'SmartActionEntity',
      },
    };
  },
  computed: {
    features() {
      return this.integration.features;
    },
    ...mapGetters([
      'companyColor',
      'companyZendeskPrimaryColor',
      'companyZendeskSecondaryColor',
      'isPluginPreferenceAllowed',
    ]),
  },
  methods: {
    ...mapActions(['initZendeskAuth', 'getApiToken', 'checkAuthExists']),
    openLink(url) {
      window.open(url, '_blank');
    },
    async copyToken() {
      const apiToken = await this.getApiToken();
      this.$copyText(apiToken).then(() => {
        this.$message({
          message:
            'Le token a été copié ! Gardez le précieusement pour le renseigner dans Zendesk',
          type: 'info',
        });
      });
    },
    async initializeZendeskAuth() {
      const { subdomain, clientId, clientSecret } = this.model;
      const res = await this.initZendeskAuth({
        subdomain,
        clientId,
        clientSecret,
      });
      if (res) {
        const settings = {
          response_type: 'code',
          redirect_uri: process.env.VUE_APP_BACK_URL + '/auth/zendesk',
          client_id: clientId,
          scope: 'read write',
          state: subdomain,
        };
        var url = new URL(
          'https://' + subdomain + '.zendesk.com/oauth/authorizations/new',
        );
        url.search = new URLSearchParams(settings);
        location.href = url.toString();
      }
      this.$message({
        message: `Vous allez être redirigé sur une page Zendesk pour valider les permissions`,
        type: 'info',
      });
    },
    respIntegrationMessage(zendesk_success) {
      if (zendesk_success === 'true') {
        this.$message({
          message: 'Intégration Zendesk réussie',
          type: 'success',
        });
      } else if (zendesk_success === 'false') {
        this.$message({
          message: "Une erreur s'est produite lors de l'intégration Zendesk",
          type: 'error',
        });
      }
    },
  },
  validations: {
    model: {
      subdomain: {
        required,
      },
      clientId: {
        required,
      },
      clientSecret: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-panel {
  max-height: 75vh;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.tab-header {
  margin-bottom: 10px;
  .tab-title {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  .tab-desc {
    color: $grey-6-mayday;
    font-size: 12px;
    font-weight: bold;
  }
}

.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 8px 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
