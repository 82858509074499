<template>
  <IntegrationSettingsMenu
    :features="features"
    :integration="integration.value"
    :preferences="preferences"
    :no-install="!hasIadvize"
    name="iAdvize"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      :success="hasIadvize"
      :title="$t('settings.integration.iadvize.title')"
      name="1"
    />
    <div class="step-container">
      <div v-if="displayInput">
        <div class="step-item">
          <div class="step-text">
            {{ $t('settings.integration.iadvize.todo.token') }}
          </div>
          <base-input
            class="w-full"
            :model-value="token"
            @update:model-value="token = $event.detail[0]"
            :disabled="loading"
            :error="error"
            :placeholder="$t('settings.integration.iadvize.placeholder.token')"
            type="password"
          />
        </div>
        <div class="step-item">
          <div class="step-text">
            {{ $t('settings.integration.iadvize.todo.project-id') }}
          </div>
          <base-input
            class="w-full"
            :model-value="projectId"
            @update:model-value="projectId = $event.detail[0]"
            :disabled="loading"
            :error="error"
            :placeholder="
              $t('settings.integration.iadvize.placeholder.project-id')
            "
          />
        </div>
        <base-button
          :disabled="loading || !token.length || !projectId.length"
          size="sm"
          type="primary"
          :text="$t('settings.integration.iadvize.update-token')"
          @click="submitToken"
        >
        </base-button>
      </div>
      <div v-else class="step-item">
        <span class="step-text">{{
          $t('settings.integration.iadvize.finished')
        }}</span>

        <base-button
          :disabled="loading"
          size="md"
          variant="secondary"
          :text="$t('settings.integration.iadvize.delete-token')"
          icon="trash"
          @click="deleteToken"
        >
        </base-button>
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';

export default {
  name: 'integration-settings-iadvize',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['companyId']),
    ...mapGetters([
      'hasIadvize',
      'companyColor',
      'companyIadvizePrimaryColor',
      'companyIadvizeSecondaryColor',
    ]),
    features() {
      return this.integration.features;
    },
  },
  data() {
    return {
      preferences: {},
      loading: false,
      error: null,
      token: '',
      projectId: '',
      displayInput: false,
    };
  },
  methods: {
    ...mapActions(['checkAuthExists', 'initIadvizeAuth', 'deleteAuth']),

    async submitToken() {
      this.loading = true;
      this.error = null;
      try {
        await this.initIadvizeAuth({
          token: this.token,
          projectId: this.projectId,
        });
        this.displayInput = false;
      } catch (e) {
        this.error = this.$t('settings.integration.iadvize.error');
      } finally {
        this.loading = false;
      }
    },

    async deleteToken() {
      this.loading = true;
      this.error = null;
      try {
        await this.deleteAuth('iadvize');
        this.displayInput = true;
      } catch (e) {
        this.error = this.$t('settings.integration.iadvize.error');
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.preferences = {
      IDZ_SEARCH_NO_STEPS: {
        title: this.$t(
          'settings.card-settings-options.integration-search.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-search.description',
        ),
        iconName: ['fas', 'search'],
        notification: this.$t(
          'settings.card-settings-options.integration-search.notification',
        ),
        hasRule: false,
      },
      IDZ_AUTOMATION_DISPLAY_DELAY: {
        title: this.$t(
          'settings.card-settings-options.integration-automation.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-automation.description',
        ),
        iconName: ['fas', 'stopwatch-20'],
        timeRange: this.$t(
          'settings.card-settings-options.integration-automation.time-range',
        ),
        defaultValue: 15,
        rule: this.$t(
          'settings.card-settings-options.integration-automation.rule',
        ),
        notification: this.$t(
          'settings.card-settings-options.integration-automation.notification',
        ),
        hasRule: true,
      },
      IDZ_PRIMARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-primary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-primary-color.description',
        )}  ${this.$t('settings.integration.chrome.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyIadvizePrimaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-primary-color.notification',
        ),
      },
      IDZ_SECONDARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-secondary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-secondary-color.description',
        )}  ${this.$t('settings.integration.chrome.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyIadvizeSecondaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-secondary-color.notification',
        ),
      },
    };
    const authOk = await this.checkAuthExists('iadvize');
    this.displayInput = !authOk;
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  .pointer {
    pointer-events: none;
  }
}
.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
