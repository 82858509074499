var render = function render(){var _vm=this,_c=_vm._self._c;return _c('IntegrationSettingsMenu',{attrs:{"name":"Salesforce","features":_vm.features,"preferences":_vm.preferences,"integration":_vm.integration.value}},[_c('IntegrationSettingsStep',{attrs:{"name":"1","title":_vm.$t('settings.integration.salesforce.step-one.title'),"success":_vm.state.success}}),_c('div',{staticClass:"step-container"},[_c('div',{staticClass:"step-item"},[_c('div',{staticClass:"step-description"},[_vm._v(" "+_vm._s(_vm.$t('settings.integration.salesforce.step-one.description'))+" ")]),_c('ol',[_c('li',[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-one.one')))]),_c('li',[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-one.two')))]),_c('li',[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-one.three')))]),_c('div',{staticClass:"step-info"},_vm._l(([1, 2, 3, 4, 5]),function(id){return _c('p',{key:id},[_vm._v(" "+_vm._s(_vm.$t(`settings.integration.salesforce.step-one.field-${id}`))+" ")])}),0),_c('li',[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-one.four')))])])])]),_c('IntegrationSettingsStep',{attrs:{"name":"2","title":_vm.$t('settings.integration.salesforce.step-two.title'),"success":_vm.state.success}}),_c('div',{staticClass:"step-container"},[_c('div',{staticClass:"step-item"},[_c('ol',[_c('li',[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-two.one')))]),_c('div',{staticClass:"step-input"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-two.instance-label')))]),_c('base-input',{attrs:{"placeholder":_vm.state.success
                ? '········'
                : _vm.$t(
                    'settings.integration.salesforce.step-two.instance-placeholder',
                  ),"model-value":_vm.model.instanceUrl,"disabled":_vm.state.success},on:{"update:model-value":function($event){_vm.model.instanceUrl = $event.detail[0]}}}),_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('settings.integration.salesforce.step-two.instance-todo')))])],1),_c('div',{staticClass:"step-input"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t( 'settings.integration.salesforce.step-two.unique-identifier-label', )))]),_c('base-input',{attrs:{"placeholder":_vm.state.success
                ? '········'
                : _vm.$t(
                    'settings.integration.salesforce.step-two.unique-identifier-placeholder',
                  ),"model-value":_vm.model.clientId,"disabled":_vm.state.success},on:{"update:model-value":function($event){_vm.model.clientId = $event.detail[0]}}})],1),_c('div',{staticClass:"step-input"},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('settings.integration.salesforce.step-two.secret-label')))]),_c('base-input',{attrs:{"placeholder":_vm.state.success
                ? '········'
                : _vm.$t(
                    'settings.integration.salesforce.step-two.secret-placeholder',
                  ),"model-value":_vm.model.clientSecret,"disabled":_vm.state.success},on:{"update:model-value":function($event){_vm.model.clientSecret = $event.detail[0]}}})],1),_c('div',{staticClass:"step-item"},[(!_vm.state.success)?_c('base-button',{attrs:{"text":_vm.$t('settings.integration.salesforce.step-two.connect'),"variant":"primary","size":"sm","disabled":_vm.$v.model.$invalid,"loading":_vm.loader.init},on:{"click":function($event){return _vm.initializeSalesforceAuth()}}}):_c('base-button',{attrs:{"text":_vm.$t('settings.integration.salesforce.step-two.modify'),"variant":"primary","size":"sm","loading":_vm.loader.delete},on:{"click":function($event){return _vm.deleteSalesforceAuth()}}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }