<template>
  <div class="d-flex rule-attribute-container justify-content-between">
    <div v-if="editable">
      <!-- COMPANY ATTRIBUTE -->
      <el-select
        class="automation-rule-select mr-2"
        v-model="localAttribute.companyAttribute"
        filterable
        value-key="id"
        :placeholder="$t('automations.main.row.choose')"
        @input="resetOtherValues"
      >
        <el-option
          v-for="attr in companyAttributes"
          :key="attr.id"
          :label="parseAttributLabel(attr)"
          :value="attr"
        />
      </el-select>

      <!-- OPERATOR -->
      <el-select
        class="automation-rule-select mr-2"
        v-model="localAttribute.operator"
        filterable
        :placeholder="$t('automations.main.row.choose')"
        v-if="focusType"
      >
        <el-option
          v-for="op in variableTypeRules[focusType]"
          :key="op.operator"
          :label="op.value"
          :value="op.operator"
        />
      </el-select>

      <!-- ATTRIBUTE VALUES -->
      <!-- IF COMPANY ATTRIBUTE -->
      <el-select
        class="automation-rule-select mr-2"
        v-model="localAttribute.companyAttributeValue"
        value-key="id"
        filterable
        v-if="restrictedAttributes.length && localAttribute.operator"
      >
        <el-option
          v-for="attrVal in restrictedAttributes"
          :key="attrVal.id"
          :label="attrVal.valueName"
          :value="attrVal"
        />
      </el-select>
      <!-- IF OPEN VALUE -->
      <tags-input
        class="value-tags-input text-left"
        :placeholder="$t('automations.main.row.word-input')"
        v-model="localAttribute.value"
        v-if="localAttribute.operator && restrictedAttributes.length === 0"
      ></tags-input>
    </div>
    <div v-else>
      <span v-if="focusCompAttr">
        {{ parseAttributLabel(focusCompAttr) }}
        <span class="automation-rule-select__operator">{{
          focusOperator
        }}</span>
        {{ focusAttrVal }}
      </span>
    </div>
    <rule-actions
      v-if="!editable && !inputDisable"
      class="d-flex ml-auto cursor"
      @edit-rule="editable = true"
      @delete-rule="$emit('delete-attribute')"
    />
    <div
      class="d-flex automation-action-options align-items-center justify-content-center"
      v-else-if="editable && !inputDisable"
    >
      <base-button
        variant="tertiary"
        icon="undo"
        icon-only
        @click="cancelUpdate"
      />
      <el-popover
        class="update-popover"
        placement="top"
        trigger="manual"
        v-model="showPopover"
      >
        <small>Veuillez completer tous les champs</small>

        <div slot="reference" class="d-flex justify-content-center ml-2">
          <base-button
            variant="tertiary"
            icon="save"
            icon-only
            :disabled="disabled"
            @click="updateRule"
            @mouseenter="triggerPopover(true)"
            @mouseleave="triggerPopover(false)"
          />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import RuleActions from './RuleActions';

export default {
  name: 'AutomationsRuleAttribute',
  components: { RuleActions },
  props: {
    attribute: {
      type: Object,
      default: () => ({
        id: '',
        companyAttribute: null,
        companyAttributeValue: null,
        value: [],
        operator: null,
      }),
    },
    isInvalidRule: {
      type: Boolean,
      default: false,
    },
    inputDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localAttribute: {
        id: '',
        companyAttribute: null,
        companyAttributeValue: null,
        value: [],
        operator: null,
      },
      variableTypeRules: {
        number: [
          {
            operator: 'is',
            value: this.$t(
              'automations.main.row.conditions.type-rules.number.is',
            ),
          },
          {
            operator: 'is_not',
            value: this.$t(
              'automations.main.row.conditions.type-rules.number.is_not',
            ),
          },
          {
            operator: 'less_than',
            value: this.$t(
              'automations.main.row.conditions.type-rules.number.less_than',
            ),
          },
          {
            operator: 'greater_than',
            value: this.$t(
              'automations.main.row.conditions.type-rules.number.greater_than',
            ),
          },
        ],
        string: [
          {
            operator: 'is',
            value: this.$t(
              'automations.main.row.conditions.type-rules.string.is',
            ),
          },
          {
            operator: 'is_not',
            value: this.$t(
              'automations.main.row.conditions.type-rules.string.is_not',
            ),
          },
          {
            operator: 'includes',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.includes',
            ),
          },
          {
            operator: 'not_includes',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.not_includes',
            ),
          },
          {
            operator: 'includes_word',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.includes_word',
            ),
          },
          {
            operator: 'not_includes_word',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.not_includes_word',
            ),
          },
          {
            operator: 'exists',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.exists',
            ),
          },
        ],
        text: [
          {
            operator: 'includes',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.includes',
            ),
          },
          {
            operator: 'not_includes',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.not_includes',
            ),
          },
          {
            operator: 'includes_word',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.includes_word',
            ),
          },
          {
            operator: 'not_includes_word',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.not_includes_word',
            ),
          },
          {
            operator: 'exists',
            value: this.$t(
              'automations.main.row.conditions.type-rules.text.exists',
            ),
          },
        ],
        boolean: [
          {
            operator: 'is',
            value: this.$t(
              'automations.main.row.conditions.type-rules.boolean.is',
            ),
          },
        ],
      },
      editable: false,
      showPopover: false,
      attributeInputs: '',
    };
  },
  created() {
    this.localAttribute = { ...this.attribute };
    if (this.localAttribute.value === null) this.localAttribute.value = [];
    if (this.localAttribute.id === '') this.editable = true;
    if (this.attribute.value !== null)
      this.attributeInputs = this.attribute.value.join();
  },
  methods: {
    triggerPopover(value) {
      this.showPopover = this.disabled && value;
    },
    updateRule() {
      if (this.disabled) return;
      const {
        id,
        companyAttribute: { id: companyAttributeId },
        companyAttributeValue,
        value,
        operator,
      } = this.localAttribute;
      this.$emit('update-rule-attribute', {
        id,
        companyAttributeId,
        companyAttributeValueId: companyAttributeValue
          ? companyAttributeValue.id
          : null,
        value,
        operator,
      });
      this.editable = false;
    },
    updateCompanyAttributeValue(value) {
      this.localAttribute.companyAttributeValue = value;
    },
    resetOtherValues() {
      this.localAttribute.operator = null;
      this.localAttribute.companyAttributeValue = null;
      this.localAttribute.value = [];
    },
    cancelUpdate() {
      if (this.localAttribute.id !== '') {
        this.localAttribute = { ...this.attribute };
        if (this.localAttribute.value === null) this.localAttribute.value = [];
      } else {
        this.$emit('cancel-new-attribute');
      }
      this.editable = false;
    },
    parseAttributLabel(attribute) {
      if (!attribute) return '';
      let { source, label } = attribute;
      source = source.charAt(0).toUpperCase() + source.slice(1);
      return `${source} - ${label}`;
    },
  },
  computed: {
    disabled() {
      const { companyAttribute, companyAttributeValue, operator, value } =
        this.localAttribute;
      const noCompanyAttribute =
        (companyAttribute === null ||
          !companyAttribute.id ||
          !companyAttribute.id.length) &&
        (companyAttributeValue === null ||
          !companyAttributeValue.id ||
          !companyAttributeValue.id.length);
      const noOperator = operator === null || !operator.length;
      const noValue =
        (value === null || value.length === 0) &&
        (!companyAttributeValue || !companyAttributeValue.value);

      return noCompanyAttribute || noOperator || noValue || this.isInvalidRule;
    },
    focusCompAttr() {
      if (this.localAttribute.companyAttribute.id) {
        return this.companyAttributes.find(
          ({ id }) => id == this.localAttribute.companyAttribute.id,
        );
      }
      return null;
    },
    focusType() {
      return this.focusCompAttr ? this.focusCompAttr.type : null;
    },
    restrictedAttributes() {
      if (this.focusCompAttr) {
        if (this.focusCompAttr.companyAttributeValues.length !== 0) {
          return this.focusCompAttr.companyAttributeValues;
        }
      }
      return [];
    },
    focusOperator() {
      if (this.focusType) {
        return this.variableTypeRules[this.focusType].find(
          (op) => op.operator === this.localAttribute.operator,
        ).value;
      }
      return null;
    },
    focusAttrVal() {
      if (
        this.restrictedAttributes.length > 0 &&
        this.localAttribute.companyAttributeValue
      ) {
        const focusAttrValId = this.localAttribute.companyAttributeValue.id;
        return this.restrictedAttributes.find((ra) => ra.id == focusAttrValId)
          .valueName;
      }
      return this.localAttribute.value.join(', ');
    },
    ...mapState(['companyAttributes']),
  },
  watch: {
    attribute() {
      this.localAttribute = { ...this.attribute };
      if (this.localAttribute.value === null) this.localAttribute.value = [];
      if (this.localAttribute.id === '') this.editable = true;
      if (this.attribute.value !== null)
        this.attributeInputs = this.attribute.value.join();
    },
  },
};
</script>

<style lang="scss" scoped>
.automation-rule-select {
  :deep() .el-input__inner {
    height: 20px;
    border: none;
    padding-left: 5px;
    font-size: 13px;
  }
}

.automation-rule-select {
  :deep() .el-input__inner:focus {
    height: 20px;
    border: none;
    padding-left: 5px;
    font-size: 13px;
  }

  &__operator {
    color: $blue-mayday;
  }
}

.automation-rule-select {
  :deep() .el-input__icon {
    line-height: 20px;
  }
}

.rule-attribute-container {
  width: 100%;
}
.collapse-rule-dropdown {
  color: $blue-mayday !important;
}
.collapse-rule-dropdown-items {
  max-height: 200px;
  overflow: auto;
}

.automation-action-icon:not(.disabled):hover {
  color: $blue-mayday;
}

.automation-action-options {
  width: fit-content;
}

.value-tags-input {
  align-items: center;
  margin-top: 5px;
  width: 100%;
  display: block;
}

.value-tags-input {
  :deep() .form-control {
    margin-top: 0px;
  }
}

.value-tags-input {
  :deep() .el-tag {
    padding-top: 0px;
    padding-bottom: 0px;
    height: auto;
    font-size: 12px;
    border-radius: 3px;
  }
}

.value-tags-input {
  :deep() .form-control {
    height: 20px;
    font-size: 13px;
    border-radius: 3px;
    border: 1px solid lightgrey;
    width: auto;
  }
}

.disabled {
  cursor: not-allowed;
}
</style>
