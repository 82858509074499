import { render, staticRenderFns } from "./IntegrationSettingsNotion.vue?vue&type=template&id=e4cf5ea8&scoped=true&"
import script from "./IntegrationSettingsNotion.vue?vue&type=script&lang=js&"
export * from "./IntegrationSettingsNotion.vue?vue&type=script&lang=js&"
import style0 from "./IntegrationSettingsNotion.vue?vue&type=style&index=0&id=e4cf5ea8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4cf5ea8",
  null
  
)

export default component.exports