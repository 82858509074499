<template>
  <BaseDropdownOptionsWrapper
    isHorizontal
    iconOnly
    :options="dropdownOptions"
    @select="handleSelectOption"
  />
</template>

<script>
import BaseDropdownOptionsWrapper from '@/components/BaseDropdownOptionsWrapper';

export default {
  name: 'rule-actions',
  components: {
    BaseDropdownOptionsWrapper,
  },
  data() {
    return {
      dropdownOptions: [
        {
          key: 'EDIT',
          label: this.$t('automations.main.row.edit'),
          icon: 'pen',
        },
        {
          key: 'DELETE',
          label: this.$t('automations.main.row.delete'),
          icon: 'trash',
          divider: true,
          type: 'danger',
        },
      ],
      localDisplay: false,
    };
  },
  methods: {
    handleSelectOption(e) {
      const key = e.detail[0];

      if (!key) return;

      if (key === 'EDIT') {
        this.triggerEvent('edit-rule');
      } else if (key === 'DELETE') {
        this.triggerEvent('delete-rule');
      }
    },
    triggerEvent(evt) {
      this.localDisplay = false;
      this.$emit(evt);
    },
  },
};
</script>
<style scoped lang="scss">
/* FA ICONS */

.option-icon {
  height: 20px;
  width: 30px;
  color: $grey-4-mayday;
}

.medium-icon {
  width: 16px;
  height: 15px;
  padding: 2px;
  color: $grey-4-mayday;
}

/* END FA ICONS */

.empty-placeholder {
  color: $grey-4-mayday;
}

.options_selection {
  max-width: 20px;
  cursor: pointer;
}

.center-element {
  justify-content: center;
  vertical-align: middle;
  display: flex;
}

.dropdown {
  padding: 0px;
  border-width: 0px;
  box-shadow: none;
  background-color: transparent;
  margin-right: 0px;
  margin: 5px;
}

.dropdown:hover {
  background-color: white;
}

.dropdown-item span {
  margin-left: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.col-1 {
  width: 400px;
}
th {
  font-weight: bold;
}

tr {
  align-items: baseline;
}

td {
  color: $unknown-blue-1-mayday;
  font-size: 13px !important;
}

.table-header {
  background-color: white;
  align-items: baseline;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.65rem;
}

.header-col {
  color: $grey-6-mayday;
}

.no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
